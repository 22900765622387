import { PaletteColor, createTheme, ThemeOptions } from "@mui/material";
import avenirNext from "@assets/fonts/avenir-next-regular.ttf";
import { darkPalette, lightPalette, PaletteType } from "@/theme/palettes.ts";

// Extend the default theme to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    customGrey: PaletteColor;
    orange: PaletteColor;
    customBlue: PaletteColor;
    customLightGrey: PaletteColor;
    neutral: PaletteColor;
    lightPurple: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    gray: PaletteColor;
  }

  interface PaletteOptions {
    customGrey: PaletteColor;
    orange: PaletteColor;
    customBlue: PaletteColor;
    customLightGrey: PaletteColor;
    neutral: PaletteColor;
    lightPurple: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    gray: PaletteColor;
  }
}

export const generateTheme = (mode: PaletteType): ThemeOptions => ({
  palette: mode === "light" ? lightPalette : darkPalette,
  shadows: [
    "none", // 0
    "rgba(0, 0, 0, 0.16) 0px 1px 4px;", // 1
    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;", // 2
    "rgba(0, 0, 0, 0.1) 0px 4px 12px;", // 3
    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;", // 4
    "0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);", // 5
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
  typography: {
    h3: {
      fontSize: "24px",
      fontWeight: "700",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "700",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "400",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "600",
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: "600",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: "400",
    },
    fontFamily: "Avenir Next, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "solid 1px #DADADA",
          "&:hover": {
            boxShadow: "-7px 7px 15px #DADADA44",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Avenir Next, sans-serif';
              src: url(${avenirNext}) format('truetype');
          `,
    },
  },
});

const theme = createTheme(generateTheme("light"));

export default theme;
